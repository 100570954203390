import React, { useEffect, useState } from 'react';
import isFeriado from '../../utils/functions/isFeriado';
import verificarQualFeriado from '../../utils/functions/verificarQualFeriado';
import './style.css';
import { fetchAulas } from '../../services/Aula/aulaService';
import { useAmbiente } from '../../context/AmbienteContext/AmbienteContext';
import { useFeriado } from '../../context/FeriadoContext/FeriadoContext';
import { useAula } from '../../context/AulaContext/AulaContext';
import { Aula } from '../../interfaces/Aula/AulaProps';
import { fetchAmbientes } from '../../services/Ambiente/ambienteService';
import { fetchFeriados } from '../../services/Feriado/feriadoService';
import axios from 'axios';
import { server } from '../../utils/api';
import Scrapping, { Scrap } from '../../components/Scrapping/Scrapping';

function Visualizacao() {
  const { ambientes, setAmbientes } = useAmbiente();
  const { feriados, setFeriados } = useFeriado();
  const { aulas, setAulas } = useAula();

  const diasDaSemana = ['seg', 'ter', 'quar', 'quin', 'sex', 'sab'];
  const periodos = ['manha', 'tarde', 'noite'];

  const [time, setTime] = useState({ hour: 0, minute: 0, second: 0 });
  const [date, setDate] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();

      setDate(now.toLocaleDateString());
      setTime({
        hour: now.getHours(),
        minute: now.getMinutes(),
        second: now.getSeconds(),
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 900000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchAulas()
      .then((data) => {
        setAulas(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar aulas:', error);
      });
    fetchAmbientes()
      .then((data) => {
        data.sort((a, b) => {
          const order = [
            'S1',
            'S2',
            's3',
            'S4',
            'S5',
            'S6',
            'S7',
            'S8',
            'S10',
            'S13',
            'S15',
            'S16',
            'S17',
            'S18',
            'S19',
            'S20',
            'OF',
          ];

          const indexA = order.indexOf(a.ambiente);
          const indexB = order.indexOf(b.ambiente);

          if (indexA === -1 && indexB === -1) {
            return 0;
          } else if (indexA === -1) {
            return 1;
          } else if (indexB === -1) {
            return -1;
          } else {
            return indexA - indexB;
          }
        });
        setAmbientes(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar ambientes:', error);
      });
    fetchFeriados()
      .then((data) => {
        setFeriados(data);
      })
      .catch((error) => {
        console.error('Erro ao buscar ambientes:', error);
      });
  }, []);

  function getViewPeriodLength(viewPeriod: string) {
    switch (viewPeriod) {
      case 'week':
        return 1;
      case 'month':
        return 4;
      case 'semester':
        return 26;
      case 'anual':
        return 52;
      default:
        return 1;
    }
  }

  const [scrap, setScrap] = useState<Scrap>({ data: [] });

  useEffect(() => {
    getScrap();
  }, [aulas]);

  async function getScrap() {
    const response = await axios.get(`${server}/curso/scrapping/bolsa`);
    if (response.status === 200) {
      setScrap(response.data);
    }
  }

  function isAulaNoDia(aula: Aula, data: string): boolean {
    const [ano, mes, dia] = aula.data.split('-').map(Number);
    const diaAula = new Date(ano, mes - 1, dia).toLocaleDateString();
    const dataFormatada = new Date(data).toLocaleDateString();
    return diaAula === dataFormatada;
  }

  const getPeriodOfDay = (hour: any) => {
    if (hour >= 7 && hour < 12) {
      return 'manhã';
    } else if (hour >= 12 && hour < 18) {
      return 'tarde';
    } else {
      return 'noite';
    }
  };

  return (
    <>
      {getPeriodOfDay(time.hour) == 'manhã' ? (
        <>
          <div className="filtroDia" id="filtroDiaVisu">
            {periodos.map((periodo) =>
              periodo == 'manha' ? (
                <div key={periodo} className={`${periodo}Container`}>
                  <h2>{periodo.charAt(0).toUpperCase() + periodo.slice(1)}</h2>
                  <div className="semanasContainer">
                    {Array.from(
                      { length: getViewPeriodLength('week') },
                      (_, weekIndex) => (
                        <table key={weekIndex}>
                          <thead>
                            <tr>
                              <th className="sala-header">Sala</th>
                              {Array.from({ length: 6 }, (_, index) => {
                                const data = new Date();
                                if (index === 5) {
                                  data.setDate(
                                    data.getDate() -
                                      data.getDay() +
                                      6 +
                                      weekIndex * 7
                                  );
                                } else {
                                  data.setDate(
                                    data.getDate() -
                                      data.getDay() +
                                      1 +
                                      index +
                                      weekIndex * 7
                                  );
                                }

                                const diaDaSemana = [
                                  'Seg',
                                  'Ter',
                                  'Qua',
                                  'Qui',
                                  'Sex',
                                  'Sab',
                                ][index];

                                const hoje = new Date();

                                const isToday =
                                  data.getDate() === hoje.getDate() &&
                                  data.getMonth() === hoje.getMonth() &&
                                  data.getFullYear() === hoje.getFullYear();

                                return (
                                  <th
                                    className={`dia ${isToday ? 'today' : ''}`}
                                    key={diaDaSemana}
                                  >
                                    <p>{diaDaSemana}</p>
                                    <p>{data.toLocaleDateString()}</p>
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {ambientes
                              .slice()
                              .sort((a, b) => {
                                const posicaoA = a.posicao
                                  ? parseInt(a.posicao, 10)
                                  : Infinity;
                                const posicaoB = b.posicao
                                  ? parseInt(b.posicao, 10)
                                  : Infinity;
                                return posicaoA - posicaoB;
                              })
                              .map((sala) => (
                                <tr key={sala._id}>
                                  {Array.from({ length: 6 }, (_, index) => {
                                    const data = new Date();
                                    data.setHours(12, 0, 0, 0); // Definindo o horário para meio-dia
                                    if (index === 5) {
                                      data.setDate(
                                        data.getDate() -
                                          data.getDay() +
                                          6 +
                                          weekIndex * 7
                                      );
                                    } else {
                                      data.setDate(
                                        data.getDate() -
                                          data.getDay() +
                                          1 +
                                          index +
                                          weekIndex * 7
                                      );
                                    }

                                    const aulasNoDia = aulas.filter(
                                      (aula) =>
                                        aula[diasDaSemana[index]] &&
                                        aula.periodo === periodo &&
                                        isAulaNoDia(aula, data + '') &&
                                        aula.sala === sala.ambiente &&
                                        !isFeriado(data, feriados)
                                    );

                                    return (
                                      <React.Fragment key={index}>
                                        {index === 0 && (
                                          <td className="sala">
                                            {sala.ambiente}
                                          </td>
                                        )}
                                        <td
                                          className="professor"
                                          style={{
                                            borderLeft: '1px solid black',
                                            borderRight: '1px solid black',
                                          }}
                                        >
                                          {isFeriado(data, feriados) ? (
                                            <div
                                              style={{
                                                backgroundColor: 'yellow',
                                                height: '100%',
                                                padding: '0px',
                                                margin: '0px',
                                                fontWeight: 'bold',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                alignContent: 'center',
                                              }}
                                            >
                                              {verificarQualFeriado(
                                                data,
                                                feriados
                                              )}
                                            </div>
                                          ) : (
                                            (() => {
                                              if (aulasNoDia.length > 0) {
                                                return aulasNoDia.map(
                                                  (aula, index) => (
                                                    <div
                                                      key={index}
                                                      data-id={aula._id}
                                                      className={`tooltip ${aula.curso}`}
                                                      style={{
                                                        cursor: 'pointer',
                                                        border:
                                                          '1px solid black',
                                                      }}
                                                    >
                                                      {aula.professor}
                                                      <span className="tooltiptext">
                                                        <p>Informações</p>
                                                        <hr />
                                                        Professor:{' '}
                                                        {aula.professor} <br />
                                                        Curso: {
                                                          aula.titulo
                                                        }{' '}
                                                        <br />
                                                        Tipo: {aula.curso}{' '}
                                                        <br />
                                                        Sala: {aula.sala} <br />
                                                        Periodo: {
                                                          aula.periodo
                                                        }{' '}
                                                        <br /> <hr />
                                                        Inicio:{' '}
                                                        {new Date(
                                                          Number(
                                                            aula.inicio.split(
                                                              '-'
                                                            )[0]
                                                          ),
                                                          Number(
                                                            aula.inicio.split(
                                                              '-'
                                                            )[1]
                                                          ) - 1,
                                                          Number(
                                                            aula.inicio.split(
                                                              '-'
                                                            )[2]
                                                          )
                                                        ).toLocaleDateString(
                                                          'pt-br'
                                                        )}{' '}
                                                        <br />
                                                        Fim:{' '}
                                                        {new Date(
                                                          Number(
                                                            aula.fim.split(
                                                              '-'
                                                            )[0]
                                                          ),
                                                          Number(
                                                            aula.fim.split(
                                                              '-'
                                                            )[1]
                                                          ) - 1,
                                                          Number(
                                                            aula.fim.split(
                                                              '-'
                                                            )[2]
                                                          )
                                                        ).toLocaleDateString(
                                                          'pt-br'
                                                        )}{' '}
                                                        <br />
                                                        <hr />
                                                      </span>
                                                    </div>
                                                  )
                                                );
                                              } else {
                                                return <>Sala Livre</>;
                                              }
                                            })()
                                          )}
                                        </td>
                                      </React.Fragment>
                                    );
                                  })}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )
            )}
          </div>
        </>
      ) : (
        <></>
      )}

      {getPeriodOfDay(time.hour) == 'tarde' ? (
        <>
          <div className="filtroDia" id="filtroDiaVisu">
            {periodos.map((periodo) =>
              periodo == 'tarde' ? (
                <div key={periodo} className={`${periodo}Container`}>
                  <h2>{periodo.charAt(0).toUpperCase() + periodo.slice(1)}</h2>
                  <div className="semanasContainer">
                    {Array.from(
                      { length: getViewPeriodLength('week') },
                      (_, weekIndex) => (
                        <table key={weekIndex}>
                          <thead>
                            <tr>
                              <th className="sala-header">Sala</th>
                              {Array.from({ length: 6 }, (_, index) => {
                                const data = new Date();
                                if (index === 5) {
                                  data.setDate(
                                    data.getDate() -
                                      data.getDay() +
                                      6 +
                                      weekIndex * 7
                                  );
                                } else {
                                  data.setDate(
                                    data.getDate() -
                                      data.getDay() +
                                      1 +
                                      index +
                                      weekIndex * 7
                                  );
                                }

                                const diaDaSemana = [
                                  'Seg',
                                  'Ter',
                                  'Qua',
                                  'Qui',
                                  'Sex',
                                  'Sab',
                                ][index];

                                const hoje = new Date();

                                const isToday =
                                  data.getDate() === hoje.getDate() &&
                                  data.getMonth() === hoje.getMonth() &&
                                  data.getFullYear() === hoje.getFullYear();

                                return (
                                  <th
                                    className={`dia ${isToday ? 'today' : ''}`}
                                    key={diaDaSemana}
                                  >
                                    <p>{diaDaSemana}</p>
                                    <p>{data.toLocaleDateString()}</p>
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {ambientes
                              .slice()
                              .sort((a, b) => {
                                const posicaoA = a.posicao
                                  ? parseInt(a.posicao, 10)
                                  : Infinity;
                                const posicaoB = b.posicao
                                  ? parseInt(b.posicao, 10)
                                  : Infinity;
                                return posicaoA - posicaoB;
                              })
                              .map((sala) => (
                                <tr key={sala._id}>
                                  {Array.from({ length: 6 }, (_, index) => {
                                    const data = new Date();
                                    data.setHours(12, 0, 0, 0); // Definindo o horário para meio-dia
                                    if (index === 5) {
                                      data.setDate(
                                        data.getDate() -
                                          data.getDay() +
                                          6 +
                                          weekIndex * 7
                                      );
                                    } else {
                                      data.setDate(
                                        data.getDate() -
                                          data.getDay() +
                                          1 +
                                          index +
                                          weekIndex * 7
                                      );
                                    }

                                    const aulasNoDia = aulas.filter(
                                      (aula) =>
                                        aula[diasDaSemana[index]] &&
                                        aula.periodo === periodo &&
                                        isAulaNoDia(aula, data + '') &&
                                        aula.sala === sala.ambiente &&
                                        !isFeriado(data, feriados)
                                    );

                                    return (
                                      <React.Fragment key={index}>
                                        {index === 0 && (
                                          <td className="sala">
                                            {sala.ambiente}
                                          </td>
                                        )}
                                        <td
                                          className="professor"
                                          style={{
                                            borderLeft: '1px solid black',
                                            borderRight: '1px solid black',
                                          }}
                                        >
                                          {isFeriado(data, feriados) ? (
                                            <div
                                              style={{
                                                backgroundColor: 'yellow',
                                                height: '100%',
                                                padding: '0px',
                                                margin: '0px',
                                                fontWeight: 'bold',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                alignContent: 'center',
                                              }}
                                            >
                                              {verificarQualFeriado(
                                                data,
                                                feriados
                                              )}
                                            </div>
                                          ) : (
                                            (() => {
                                              if (aulasNoDia.length > 0) {
                                                return aulasNoDia.map(
                                                  (aula, index) => (
                                                    <div
                                                      key={index}
                                                      data-id={aula._id}
                                                      className={`tooltip ${aula.curso}`}
                                                      style={{
                                                        cursor: 'pointer',
                                                        border:
                                                          '1px solid black',
                                                      }}
                                                    >
                                                      {aula.professor}
                                                      <span className="tooltiptext">
                                                        <p>Informações</p>
                                                        <hr />
                                                        Professor:{' '}
                                                        {aula.professor} <br />
                                                        Curso: {
                                                          aula.titulo
                                                        }{' '}
                                                        <br />
                                                        Tipo: {aula.curso}{' '}
                                                        <br />
                                                        Sala: {aula.sala} <br />
                                                        Periodo: {
                                                          aula.periodo
                                                        }{' '}
                                                        <br /> <hr />
                                                        Inicio:{' '}
                                                        {new Date(
                                                          Number(
                                                            aula.inicio.split(
                                                              '-'
                                                            )[0]
                                                          ),
                                                          Number(
                                                            aula.inicio.split(
                                                              '-'
                                                            )[1]
                                                          ) - 1,
                                                          Number(
                                                            aula.inicio.split(
                                                              '-'
                                                            )[2]
                                                          )
                                                        ).toLocaleDateString(
                                                          'pt-br'
                                                        )}{' '}
                                                        <br />
                                                        Fim:{' '}
                                                        {new Date(
                                                          Number(
                                                            aula.fim.split(
                                                              '-'
                                                            )[0]
                                                          ),
                                                          Number(
                                                            aula.fim.split(
                                                              '-'
                                                            )[1]
                                                          ) - 1,
                                                          Number(
                                                            aula.fim.split(
                                                              '-'
                                                            )[2]
                                                          )
                                                        ).toLocaleDateString(
                                                          'pt-br'
                                                        )}{' '}
                                                        <br />
                                                        <hr />
                                                      </span>
                                                    </div>
                                                  )
                                                );
                                              } else {
                                                return <>Sala Livre</>;
                                              }
                                            })()
                                          )}
                                        </td>
                                      </React.Fragment>
                                    );
                                  })}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )
            )}
          </div>
        </>
      ) : (
        <></>
      )}

      {getPeriodOfDay(time.hour) == 'noite' ? (
        <>
          <div className="filtroDia" id="filtroDiaVisu">
            {periodos.map((periodo) =>
              periodo == 'noite' ? (
                <div key={periodo} className={`${periodo}Container`}>
                  <h2>{periodo.charAt(0).toUpperCase() + periodo.slice(1)}</h2>
                  <div className="semanasContainer">
                    {Array.from(
                      { length: getViewPeriodLength('week') },
                      (_, weekIndex) => (
                        <table key={weekIndex}>
                          <thead>
                            <tr>
                              <th className="sala-header">Sala</th>
                              {Array.from({ length: 6 }, (_, index) => {
                                const data = new Date();
                                if (index === 5) {
                                  data.setDate(
                                    data.getDate() -
                                      data.getDay() +
                                      6 +
                                      weekIndex * 7
                                  );
                                } else {
                                  data.setDate(
                                    data.getDate() -
                                      data.getDay() +
                                      1 +
                                      index +
                                      weekIndex * 7
                                  );
                                }

                                const diaDaSemana = [
                                  'Seg',
                                  'Ter',
                                  'Qua',
                                  'Qui',
                                  'Sex',
                                  'Sab',
                                ][index];

                                const hoje = new Date();

                                const isToday =
                                  data.getDate() === hoje.getDate() &&
                                  data.getMonth() === hoje.getMonth() &&
                                  data.getFullYear() === hoje.getFullYear();

                                return (
                                  <th
                                    className={`dia ${isToday ? 'today' : ''}`}
                                    key={diaDaSemana}
                                  >
                                    <p>{diaDaSemana}</p>
                                    <p>{data.toLocaleDateString()}</p>
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {ambientes
                              .slice()
                              .sort((a, b) => {
                                const posicaoA = a.posicao
                                  ? parseInt(a.posicao, 10)
                                  : Infinity;
                                const posicaoB = b.posicao
                                  ? parseInt(b.posicao, 10)
                                  : Infinity;
                                return posicaoA - posicaoB;
                              })
                              .map((sala) => (
                                <tr key={sala._id}>
                                  {Array.from({ length: 6 }, (_, index) => {
                                    const data = new Date();
                                    data.setHours(12, 0, 0, 0); // Definindo o horário para meio-dia
                                    if (index === 5) {
                                      data.setDate(
                                        data.getDate() -
                                          data.getDay() +
                                          6 +
                                          weekIndex * 7
                                      );
                                    } else {
                                      data.setDate(
                                        data.getDate() -
                                          data.getDay() +
                                          1 +
                                          index +
                                          weekIndex * 7
                                      );
                                    }

                                    const aulasNoDia = aulas.filter(
                                      (aula) =>
                                        aula[diasDaSemana[index]] &&
                                        aula.periodo === periodo &&
                                        isAulaNoDia(aula, data + '') &&
                                        aula.sala === sala.ambiente &&
                                        !isFeriado(data, feriados)
                                    );

                                    return (
                                      <React.Fragment key={index}>
                                        {index === 0 && (
                                          <td className="sala">
                                            {sala.ambiente}
                                          </td>
                                        )}
                                        <td
                                          className="professor"
                                          style={{
                                            borderLeft: '1px solid black',
                                            borderRight: '1px solid black',
                                          }}
                                        >
                                          {isFeriado(data, feriados) ? (
                                            <div
                                              style={{
                                                backgroundColor: 'yellow',
                                                height: '100%',
                                                padding: '0px',
                                                margin: '0px',
                                                fontWeight: 'bold',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                alignContent: 'center',
                                              }}
                                            >
                                              {verificarQualFeriado(
                                                data,
                                                feriados
                                              )}
                                            </div>
                                          ) : (
                                            (() => {
                                              if (aulasNoDia.length > 0) {
                                                return aulasNoDia.map(
                                                  (aula, index) => (
                                                    <div
                                                      key={index}
                                                      data-id={aula._id}
                                                      className={`tooltip ${aula.curso}`}
                                                      style={{
                                                        cursor: 'pointer',
                                                        border:
                                                          '1px solid black',
                                                      }}
                                                    >
                                                      {aula.professor}
                                                      <span className="tooltiptext">
                                                        <p>Informações</p>
                                                        <hr />
                                                        Professor:{' '}
                                                        {aula.professor} <br />
                                                        Curso: {
                                                          aula.titulo
                                                        }{' '}
                                                        <br />
                                                        Tipo: {aula.curso}{' '}
                                                        <br />
                                                        Sala: {aula.sala} <br />
                                                        Periodo: {
                                                          aula.periodo
                                                        }{' '}
                                                        <br /> <hr />
                                                        Inicio:{' '}
                                                        {new Date(
                                                          Number(
                                                            aula.inicio.split(
                                                              '-'
                                                            )[0]
                                                          ),
                                                          Number(
                                                            aula.inicio.split(
                                                              '-'
                                                            )[1]
                                                          ) - 1,
                                                          Number(
                                                            aula.inicio.split(
                                                              '-'
                                                            )[2]
                                                          )
                                                        ).toLocaleDateString(
                                                          'pt-br'
                                                        )}{' '}
                                                        <br />
                                                        Fim:{' '}
                                                        {new Date(
                                                          Number(
                                                            aula.fim.split(
                                                              '-'
                                                            )[0]
                                                          ),
                                                          Number(
                                                            aula.fim.split(
                                                              '-'
                                                            )[1]
                                                          ) - 1,
                                                          Number(
                                                            aula.fim.split(
                                                              '-'
                                                            )[2]
                                                          )
                                                        ).toLocaleDateString(
                                                          'pt-br'
                                                        )}{' '}
                                                        <br />
                                                        <hr />
                                                      </span>
                                                    </div>
                                                  )
                                                );
                                              } else {
                                                return <>Sala Livre</>;
                                              }
                                            })()
                                          )}
                                        </td>
                                      </React.Fragment>
                                    );
                                  })}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )
            )}
          </div>
        </>
      ) : (
        <></>
      )}

      {/* QRCODE */}
      <div className="qrcode">
        <div className="creditos-nome">
          <h2>GRA - Gerenciamento Rápido de Ambientes</h2>
          <h3>Desenvolvido pelo Prof° Arthur</h3>
        </div>
        <h2 style={{ textAlign: 'center', fontSize: '48px' }}>
          {`${time.hour.toString().padStart(2, '0')}:${time.minute
            .toString()
            .padStart(2, '0')}:${time.second.toString().padStart(2, '0')}`}
        </h2>
        <p style={{ textAlign: 'center', fontSize: '28px' }}>{date}</p>

        <div style={{ textAlign: 'center' }}>
          {/*           <h3>Acompanhe as Aulas!</h3>
          <h2>Acesse abaixo!</h2>
          <img alt="QRCODE" src="./frame.png" /> */}

          <h2>
            {getPeriodOfDay(time.hour) == 'noite'
              ? 'Boa noite!'
              : getPeriodOfDay(time.hour) == 'tarde'
                ? 'Boa tarde!'
                : 'Bom dia!'}
          </h2>
          {getPeriodOfDay(time.hour) == 'noite' ? (
            <>
              <img
                alt=""
                src="https://gerador-de-bom-dia.vercel.app/api/generate?periodo=noite&tipo=png"
              />
            </>
          ) : getPeriodOfDay(time.hour) == 'tarde' ? (
            <>
              <img
                alt=""
                src="https://gerador-de-bom-dia.vercel.app/api/generate?periodo=tarde&tipo=png"
              />
            </>
          ) : (
            <>
              <img
                alt=""
                src="https://gerador-de-bom-dia.vercel.app/api/generate?periodo=manha&tipo=png"
              />
            </>
          )}
        </div>
      </div>

      {/* Noticias */}
      <Scrapping scrap={scrap} />
    </>
  );
}

export default Visualizacao;
